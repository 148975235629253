<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                携手中粮期货、国信证券共同见证数智交易前沿力量
              </p>
              <div class="fst-italic mb-2">2024年7月23日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >中粮期货&国信证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/85/01-致辞.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，中粮期货、国信证券联合非凸科技在深圳举办了“粮信甄选，非凡凸现”主题机构洽谈会，与行业专家、私募管理人共同探讨如何推进产业与金融的深度融合，以及实现科技创新与生态合作的新模式。
                  </p>
                  <p>
                    近年来，国信证券始终聚焦业务实际痛点，以客户为中心，建设多渠道一体化金融科技平台，满足客户差异化需求，有效赋能公司高质量发展。会上，国信证券深圳分公司战略发展部总经理、红岭分公司副总经理靳勇发表致辞，作为资本市场服务机构，我们希望在此搭建一个高效的交流平台，促进经验分享，构建新政策、新机遇下合作共赢的行业新生态。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/85/02-中粮.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    中粮期货作为国内领先的产业特色衍生品金融服务公司，历经多年创新发展，坚持服务实体经济，携手产业企业共成长。中粮期货深圳分公司副总经理孟醒以“筑巢引凤——破冰金融服务新局面”为主题进行了分享，中粮期货旨在通过构建产融互通的桥梁，促进产业与金融机构之间的资源整合、差异化服务，实现合作共赢。在严监管的市场环境下，倡导行业生态圈共同应对挑战，利用专业优势探索创新业务模式，打造完善的服务体系。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/85/03-合集.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，久期量和投资副总经理胡慧、鸣石基金副总裁杨雪、中安鼎盛合伙人陈伯仲、大道投资市场总监郑锦涛、安子基金市场经理廖泽、中粮期货资管业务经理陈刚等行业专家，从多角度介绍了机构量化策略的变化与未来布局方向，并结合数据趋势分析，让与会者对各家量化策略的特点及优势有了更清晰的认识。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/85/04-非凸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    依托不同金融机构的资源禀赋开展生态合作，已然成为行业的新趋势。非凸科技作为伴随中国数智交易成长的金融科技企业，凭借长期坚守科技创新助力行业发展的优异实践表现，成为了市场上券商拓展业务的首选供应商。非凸科技深圳分公司合伙人曹靖康以“非凸算法如何赋能数智交易”为主题展开了分享，金融市场瞬息万变，为了全方位满足投资者精细化、智能化、多样化的交易需求，非凸科技利用科技创新，提供高质量的金融服务产品和交易工具，构建一个不断突破的数智交易体系。
                  </p>
                  <p>
                    活动的最后，与会者就行业趋势、算法交易及生态合作等方面展开了深度交流，期待未来能持续进行行业观点及专业视角的开放交流。
                  </p>
                  <p>
                    非凸科技也将携手行业生态伙伴，不断推动数智交易领域的技术创新与应用拓展，为金融行业的持续健康发展贡献更多力量。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News85",
};
</script>

<style></style>
